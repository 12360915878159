import styled from "styled-components";

export const LayoutStyle = styled.div`
    min-width: 320px;
    max-width: 100%;
    /* width: 1280px; */
    width: 100%;
    height: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
`;
